<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col sm="12" class="text-center mb-4">
          <h1>{{ $t('signin.forgotPassword')}}</h1>
        </b-col>
        <b-col sm="12">
           <p class="mx-5">
            {{ $t('signin.forgotPassDesc') }}
          </p>
        </b-col>
        <b-col md="6" offset-md="3" lg="4" offset-lg="4">
          <div v-if="successFullySend" class="d-block valid-feedback mb-3"> {{ $t('signin.successfullySendEmailReset') }}</div>
          <div v-else>
          <p>
            <b-form-input id="email" :state="emailState" v-model="email" :placeholder="$t('signin.enterEmail')" autocomplete="new-password" trim></b-form-input>
            <b-form-invalid-feedback :state="emailState">{{ $t('signin.enterEmailError') }}</b-form-invalid-feedback>
          </p>
          <div v-if="noUser" class="d-block invalid-feedback mb-3">{{ $t('signin.noUserForResetPass') }}</div>
          <div class="text-center">
            <b-overlay :show="buttonSpinner" spinner-small spinner-variant="secondary" opacity="0.5" class="d-inline-block">
              <button type="submit" :disabled="buttonSpinner" class="btn btn-primary" @click="sendLink">{{ $t('signin.sendNewPasswordLink') }}</button>
            </b-overlay>
          </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name : "ForgotPassword",
  data() {
    return {
     email: "",
     emailState: null,
     noUser: null,
     successFullySend: false,
     buttonSpinner: false
    }
  },
  created() {

  },
  methods: {
    async sendLink() {
      if (this.validateForm()) {
        this.buttonSpinner = true;  
        await this.$http.get('/api/users/get/email/' + this.email).then(r => {
          if (r.data === "") {
            // User ne postoji
            this.noUser = true;
          } else {
            this.noUser = false;
            this.successFullySend = true;
          }
        }).catch(e => {  });
        this.buttonSpinner = false;  
      }
    },
    validateForm() {
       if (typeof this.email === "undefined" || this.email.length === 0) {
          this.emailState = false;
          document.getElementById("email").scrollIntoView({ block: 'center',  behavior: 'smooth' });
          return false;
        } else {
          this.emailState = null;
        }
        if (!this.isEmailAddress(this.email)) {
          this.emailState = false;
          document.getElementById("email").scrollIntoView({ block: 'center',  behavior: 'smooth' });
          return false;
        } else {
          this.emailState = null;
        }
        return true;
    },

    isEmailAddress(str) {
      const pattern = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,10}$/;
      return pattern.test(str);
    }
  }
}
</script>

<style></style>

